export const validators = {
  mail: /^([~!?#$%^&()_\-+={|`}a-zA-Z0-9]+[_|\_|\.]?)*[a-zA-Z0-9]+@([a-zA-Z0-9]+[_|\_|\.]?)*[a-zA-Z0-9]+\.[a-zA-Z]{2,3}$/,
  creditCode: /^[a-zA-Z0-9]{18}$/, //信用代码
  userName: /(^[a-z,A-Z]{1}[a-z,A-Z,·, ]*[a-z,A-Z]{1}$)|(^[\u4e00-\u9fa5]*$)/,
  card: /^[0-9]{17}[xX0-9]$/,
  bucketName: /^[a-z,0-9][a-z,0-9,\-,\_]{1,61}[a-z,0-9]$/,
  folderName: /^[@、\._\[\]\-:0-9A-Za-z\u4e00-\u9fa5]*$/,
  imgSetName: /^[a-z,A-Z,0-9,-]{3,63}$/
};

export const validMail = val => validators.mail.test(val);
export const validPwd = val => {
  var result = "";
  if (val.length < 8 || val.length > 20) {
    result = "长度在8-20个字符之间";
  } else {
    let list = val.split(''), res = [0,0,0,0]
    list.forEach(item => {
      if (/^\d$/.test(item)) {
        res[0] = 1
      } else if (/^[a-z]$/.test(item)) {
        res[1] = 1
      } else if (/^[A-Z]$/.test(item)) {
        res[2] = 1
      } else { // 特殊字符
        res[3] = 1
      }
    })
    if (res.reduce((pre, cur) => pre + cur, 0) < 3) {
      result = '支持大写字母、小写字母、数字、特殊字符组合，需包含三类字符'
    }
  }
  return result;
};
export const validCreditCode = val => validators.creditCode.test(val);
export const validUserName = val => validators.userName.test(val);
export const validFolderName = val => validators.folderName.test(val);
export const validBucketName = val => validators.bucketName.test(val);
export const validImgSetName = val => validators.imgSetName.test(val);
export const validPhone = val => {
  if (val.length == 11) {
    return /^[1]{1}[0-9]{10}$/.test(val);
  } else if (val.length == 13) {
    return /^[0-9]{13}$/.test(val);
  } else if (val.substr(0, 1) == "+") {
    var e = val.substr(1);
    if (e.length != 13) {
      return false;
    }
    return /^[0-9]{13}$/.test(e);
  } else {
    return false;
  }
};
export const valiNewFolderName = val => {
  if (val == null || val == "") {
    return true;
  }
  if (val.length == 1) {
    return /^[^\/\&\<\>\$\(\)]{1}$/.test(val);
  } else if (val.length == 2) {
    return /^[^\/\&\<\>\$\(\)]{1,2}$/.test(val);
  } else {
    var isR = /^[^\/\&\<\>\$\(\)]{1}[^\&\<\>\$\(\)]{1,}[^\/\.\&\<\>\$\(\)]{1}$/.test(
      val
    );
    if (!isR) {
      return isR;
    } else {
      var isT = true;
      while (true) {
        var ind = val.indexOf("/");
        if (ind == -1) {
          break;
        }
        var a_ind = val.substr(ind + 1, 1);
        if (a_ind == "/") {
          isT = false;
          break;
        }
        val = val.substr(ind + 1, val.length - ind - 1);
      }
      return isT;
    }
  }
};
export const validCard = val => validators.card.test(val);
export default {
  validMail,
  validPwd,
  validCreditCode,
  validUserName,
  validBucketName,
  validFolderName,
  valiNewFolderName,
  validPhone,
  validCard
};
