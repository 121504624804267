<template>
    <div class="create-user-region-list">
        <el-row class="item" :gutter="5" align="left" v-for="(item, index) in list">
            <el-col span="4">

                <el-select style="width: 100%;" placeholder="选择资源池" size="small" :value="item.regionId"
                           @change="handleRegionIdChange($event, index)">
                    <el-option
                        v-for="item in regionList"
                        :disabled="disabledRegion.indexOf(item.regionId) > -1"
                        :key="item.regionId"
                        :label="item.regionName"
                        :value="item.regionId"></el-option>
                </el-select>
            </el-col>
            <el-col span="3">
                <el-input-number
                    placeholder="请输入配额"
                    style="width: 100%;"
                    :value="item.storageSpecs"
                    :precision="0"
                    :controls="false"
                    @input="handleChange1($event, index, 'storageSpecs')"
                    @blur="hasChange"
                    size="small">
                </el-input-number>
            </el-col>
            <el-col span="3">
                <el-select @change="handleChange2($event, index)" :value="item.storageUnit" size="small" placeholder="选择单位">
                    <el-option v-for="item in units" :label="item.label" :value="item.value" :key="item.value"></el-option>
                </el-select>
            </el-col>
            <el-col span="4">
                <el-select :value="item.policyId"

                           @change="handlePlacementRulesChange($event, index)" size="small"
                           placeholder="选择存储策略">
                    <el-option v-for="item in (placementRules[item.regionId] || [])" :label="item" :value="item" :key="item"></el-option>
                </el-select>
            </el-col>
            <el-col span="4">
                <div class="action-btn" >
                    <i v-if="list.length > 1" @click="handleDelete(index) "class="el-icon-remove-outline"/>
                    <i v-if="index === list.length - 1"  @click="handlePlus" class="el-icon-circle-plus-outline"/>
                </div>
            </el-col>
        </el-row>

    </div>
</template>

<script>
import storage from "@/utils/storage";
import storageInput from "@/views/components/StorageInput";


export default {
    name: "region-list",
    components: {storageInput},
    computed: {
        disabledRegion () {
            return this.list.map(i => i.regionId)
        }
    },
    props: {
        isCreate: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            list: [
                {
                    policyId: '',
                    regionId: '',
                    storageSpecs: undefined,
                    storageUnit: ''
                }
            ],
            regionList: [],
            units: [
                {label: 'GB', value: 4},
                {label: 'TB', value: 5},
                {label: 'PB', value: 6}],
            placementRules: {},
            timeout: null,
        }
    },
    mounted() {
        this.getRegions()
        this.listPlacementRules()
    },
    methods: {
        hasChange () {
            if (this.isCreate) {
                return
            }
            if (this.timeout) {
                clearTimeout(this.timeout)
            }
            this.timeout = setTimeout(() => {
                this.$emit('change')
            }, 1000)
        },
        initList (list) {
            this.list = list
        },
        // /api/dashboard/listPlacementRules
        listPlacementRules () {
            this.$get('/dashboard/listPlacementRules')
            .then(res => {
                if (res.code === '200') {
                    this.placementRules = res.data
                }
            })
        },
        getRegionCheckedList () {
            const {list} = this
            for (let i = 0; i < list.length; i++) {
                let msg = ''
                if (!list[i].regionId) {
                    msg = '请选择资源池'
                } else if (!list[i].storageSpecs && list[i].storageSpecs != '0') {
                    msg = '请输入配额'
                } else if (!list[i].storageUnit) {
                    msg = '请选择单位'
                } else if (!list[i].policyId) {
                    msg = '请选择存储策略'
                }
                // 新增资源池所有项目未填，不报错； 项目未填满，报错
                if (msg && !(!list[i].regionId && (!list[i].storageSpecs && list[i].storageSpecs != '0') && !list[i].storageUnit && !list[i].policyId)) {
                    this.$message.warning(msg)
                    return  null
                }
            }


            return JSON.parse(JSON.stringify(this.list.filter(item => item.policyId && item.regionId && (item.storageSpecs || item.storageSpecs == '0') && item.storageUnit)))
        },
        handleDelete (index) {
            this.list.splice(index, 1)
        },
        handlePlus () {
            this.list.push({
                policyId: '',
                regionId: '',
                storageSpecs: undefined,
                storageUnit: ''
            })
        },
        handleChange1(val, index) {
            this.$set(this.list[index], 'storageSpecs', val)

        },
        handleChange2(val, index) {
            this.$set(this.list[index], 'storageUnit', val)
            this.hasChange()
        },
        handleRegionIdChange(val, index) {
            this.$set(this.list[index], 'regionId', val)
            this.$set(this.list[index], 'policyId', '')
        },
        getRegions() {
            this.$postJson('/region/listPurchased')
                .then(res => {
                    if (res.code === '200') {
                        this.regionList = res.data
                    }
                })
        },
        handlePlacementRulesChange(val, index) {
            this.$set(this.list[index], 'policyId', val)
        }
    }
}
</script>

<style scoped>
.create-user-region-list {
    transform: translateX(-5px);
}
.action-btn {
    display: flex;
    text-align: left;
    font-size: 24px;
    cursor: pointer;
    color: #999;
    margin-top: 8px;
}
</style>