<template>
    <el-breadcrumb class="crumb" separator="/">
        <span v-if="!!parentTitle" @click="goBack" class="go-back-icon"><i class="el-icon-arrow-left"/></span>
        <el-breadcrumb-item v-if="!!parentTitle" :to="{ path: backPath }" class="bucket-crumb">{{ parentTitle }}</el-breadcrumb-item>
        <el-breadcrumb-item class="bucket-crumb">{{ title }}</el-breadcrumb-item>
    </el-breadcrumb>
</template>

<script>
export default {
    name: "breadcrumb-back",
    props: ['parentTitle', 'title', 'backPath'],
    methods: {
        goBack () {
            this.$router.push(this.backPath)
        }
    }

}
</script>

<style scoped>

</style>