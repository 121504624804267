// import ilakeVue from "ilake-vue";
// import cryptoJs from "crypto-js";
export const formatBytes = function(bytes, decimals = 2) {
  if (bytes === null || bytes === undefined) {
    return "-";
  }
  bytes = parseInt(bytes);
  if (isNaN(bytes)) {
    return "-";
  }
  if (bytes == 0) return "0B";
  if (bytes < 0) return "无限制";
  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["B", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + "" + sizes[i];
};
export const percentage = function(use, all) {
  var used = (use / all) * 100;
  if (all == "" || all == 0 || all == "-") {
    return "0";
  } else {
    if (used > 100) {
      return "100%";
    } else {
      return used + "%";
    }
  }
};

export const sleep = function(duration) {
  return new Promise(resolve => setTimeout(resolve, duration));
};
// export const passwordEncrypt = function(password) {
//   const PUBLIC_KEY =
//     "MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCmbSqHmnMXeY468Lyr7pjAuGqjRlSabx2Kt7LmypAFQf8nZMSCK5f2w+UY+xksGj1YlcXqIMc4VdfLVI8OZQUGWyditv2csQQhSd2XOT+7m3euc4htilHhPXMn/dQ2LebnYWQ2lbF2V42n5maT2mLjB/BcXjbAzmzNQEwYZlczNQIDAQAB";
//   localStorage.setItem("ILAKE_PUBLICKEY", PUBLIC_KEY);
//   return ilakeVue.utils.encryptPwd(password);
// };
// export const passwordDecrypt = function(password) {
//   //图片解密
//   var key = cryptoJs.enc.Utf8.parse("ehualu1234567890");
//   var decryptedData = cryptoJs.AES.decrypt(password, key, {
//     mode: cryptoJs.mode.ECB,
//     padding: cryptoJs.pad.Pkcs7
//   });
//
//   return decryptedData.toString(cryptoJs.enc.Utf8);
// };
// export const objEncrypt = function(password) {
//   var pwd = JSON.stringify(password);
//   var key = cryptoJs.enc.Utf8.parse("ehualu1234567890");
//   var decryptedData = cryptoJs.AES.encrypt(pwd, key, {
//     mode: cryptoJs.mode.ECB,
//     padding: cryptoJs.pad.Pkcs7
//   });
//
//   decryptedData = decryptedData.ciphertext.toString();
//   var encryptedHexStr = cryptoJs.enc.Hex.parse(decryptedData);
//   return cryptoJs.enc.Base64.stringify(encryptedHexStr);
// };
export const checkNull = function(value) {
  if (
    !value ||
    value === null ||
    typeof value === "undefined" ||
    value === ""
  ) {
    return true;
  }
  return false;
};
// export const fileMD5 = function(blob) {
//   return ilakeVue.utils.md5File(blob, CONSTANT.MAX_UPLOADER_PART_SIZE);
// };
// export const blobSlice = ilakeVue.utils.blobSlice;

export const units = [
  "B",
  "KB",
  "MB",
  "GB",
  "TB",
  "PB",
  "EB",
  "ZB",
  "YB",
  "BB"
];
/**
 * 大小转换 数字转换字符串 1024 = 1KB
 * @param {Number} quota byte大小
 * @param {Number} idx 单位units索引
 * @returns
 */
export const getQuotaAndUnit = (quota, max = 9, idx = 0) => {
  if (quota === 0 || quota < 1024 || idx === max) {
    return {
      value: parseInt(quota),
      unit: units[idx]
    };
  } else {
    return getQuotaAndUnit(quota / 1024, max, idx + 1);
  }
};

/**
 * 字符串大小转换成byte大小 1KB = 1024
 * @param {String} str
 * @returns bytes number大小
 */
export const toBytes = (str = "") => {
  let bytes = parseInt(str);
  for (let i = 1; i < units.length; i++) {
    const unit = units[i];
    if (str.endsWith(unit)) {
      return bytes * Math.pow(1024, i);
    }
  }
  return bytes;
};

export const formatSeconds = value => {
  let theTime = parseInt(value); // 秒
  let theTime1 = 0; // 分
  let theTime2 = 0; // 小时
  if (theTime > 60) {
    theTime1 = parseInt(theTime / 60);
    if (theTime1 >= 60) {
      theTime2 = parseInt(theTime1 / 60);
      theTime1 = parseInt(theTime1 % 60);
    }
  }
  let result = "";
  if (theTime1 >= 0) {
    result = `${theTime1 >= 0 && theTime1 < 10 ? "0" : ""}${parseInt(
      theTime1
    )}${result}`;
  }
  if (theTime2 >= 0) {
    result = `${theTime2 >= 0 && theTime2 < 10 ? "0" : ""}${parseInt(
      theTime2
    )}:${result}`;
  }
  return result;
};

export default {
  formatBytes,
  percentage,
  sleep,
  // passwordEncrypt,
  // passwordDecrypt,
  // objEncrypt,
  checkNull,
  // fileMD5,
  // blobSlice,
  toBytes,
  getQuotaAndUnit,
  formatSeconds
};
