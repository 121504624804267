<template>
    <el-input :placeholder="placeholder" :disabled="disabled" :size="size" v-model="storageValue"
              @input="onInput"
              @blur="filterNum"
              class="quota_box">
        <el-select slot="append" v-model="storageUnit" :disabled="disabled" :size="size" @change="filterNum">
            <el-option v-for="item in units" :label="item" :value="item" :key="item"></el-option>
        </el-select>
    </el-input>
</template>

<script>
import utils from '../../utils/utils2'

export default {
    name: 'StorageInput',
    components: {},
    props: {
        // form表单中容量大小的字段名
        value: {
            type: Number | String,
            default: 0,
        },
        size: {
            type: String,
            default: 'medium',
        },
        units: {
            type: Array,
            default: () => ['B', 'KB', 'MB', 'GB'],
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        type: {
            type: String,
            default: 'number',
        },
        placeholder: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            storageValue: '',
            storageUnit: this.units[0],
        }
    },
    watch: {
        value(val) {
            this.init()
        },
    },
    mounted() {
        this.init()
    },
    methods: {
        onInput() {

            const value = parseInt(this.storageValue);
            if (isNaN(value) || value < 0) {
                this.storageValue = this.storageValue || '';
            } else {
                this.storageValue = value;
            }
        },
        init() {
            // 初始化数据
            if (typeof this.value === 'number') {
                if (this.value == 0) {
                    this.storageValue = 0
                    return
                }
                const {value, unit} = utils.getQuotaAndUnit(this.value)
                this.storageValue = value
                this.storageUnit = this.units.includes(unit) ? unit : this.units[0]
            } else if (typeof this.value === 'string') {
                if (this.value == '') {
                    this.storageValue = ''
                    return
                }
                const {value, unit} = utils.getQuotaAndUnit(utils.toBytes(this.value))
                this.storageValue = value
                this.storageUnit = this.units.includes(unit) ? unit : this.units[0]
            }
            // this.filterNum()
        },
        filterNum() {
            // 换算结果回写表单
            if (this.storageValue == '' || this.storageValue == '0') {
                this.$emit('input', 0)
                return
            }

            let value = utils.toBytes(`${this.storageValue}${this.storageUnit}`)
            console.log(this.storageUnit, this.storageValue, value)
            this.$emit('input', value)
        },
    },
}
</script>

<style lang='scss' scoped>
.quota_box {
    /deep/ {
        > .el-input__inner {
            text-align: right;
        }

        .el-input-group__append {
            width: 50px;
            height: 30px;

            div.el-select {

                .el-input__inner {
                    border-left-color: transparent;
                    border-radius: 0 4px 4px 0;
                    padding: 0 20px;
                }

                .el-input__suffix {
                    right: 10px;
                }
            }
        }
    }
}
</style>
