<template>
    <img v-if="src" :src="src" alt="">
    <i v-else class="el-icon-loading"></i>
</template>

<script>
/**
 * 后台图片base64 存在数据库，
 * 返回前端图片id，需要调接口回显

 * **/
export default {
    name: "image-user",
    props: ['id'],
    watch: {
        id () {
            this.getImage()
        }
    },
    data () {
        return {
            src: ''
        }
    },
    mounted() {
        this.getImage()
    },
    methods: {
        getImage () {
            if (!this.id) {
                return
            }
            this.$get('/img/base64?imgId=' + this.id)
            .then(res => {
                if (res.code === '200') {
                    this.src = 'data:image/png;base64,' + res.data
                }
            })
        }
    }
}
</script>

<style scoped>

</style>